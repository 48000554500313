<template>
  <div class="musea-filter container" ref="filter-root">
    <div class="top-row row p-3 bg-white border rounded">
      <div class="filter-block col-12 col-md-4">
        <div class="filter pl-0 pr-0 pr-sm-2">
          <search-input v-model="searchFilterValue" :placeholder="searchPlaceholder" />
        </div>

        <div
          class="filter pl-0 pr-0 pr-sm-2"
          v-for="classifier in classifierListData"
          :key="classifier.id"
        >
          <classifier-select
            :filterData="classifier"
            v-model="classifierValues[classifier.id]"
            :placeholder="`${filterOnString} ${classifier.name}`"
          />
        </div>

        <div v-if="loading" class="mt-3 mb-3 d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <div class="map-block col-12 col-md-8">
        <GoogleMapLoader :mapConfig="mapConfig" :apiKey="googleMapsKey" v-slot="{ google, map }">
          <GoogleMapGeoJson :google="google" :map="map" :geojson="geojson" />
          <GoogleMapInfoWindow
            :google="google"
            :map="map"
            :selectedMarker="selectedMarker"
            v-on:closed="popupClosed"
          />
          <template v-if="queryResult && google">
            <GoogleMapMarkerCluster
              :google="google"
              :map="map"
              imagePath="https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
              v-slot="{ markerCluster }"
            >
              <GoogleMapSpiderfier :google="google" :map="map" v-slot="{ spiderfier }">
                <GoogleMapMarker
                  v-for="item in queryResult.data"
                  :key="item.id"
                  :marker="item.to_geojson"
                  :google="google"
                  :map="map"
                  :markerCluster="markerCluster"
                  :spiderfier="spiderfier"
                  :icon="icon"
                  v-on:marker-selected="markerSelected"
                />
              </GoogleMapSpiderfier>
            </GoogleMapMarkerCluster>
          </template>
        </GoogleMapLoader>
      </div>
    </div>

    <template v-if="queryResult">
      <div v-if="queryResult.data">
        <div class="m-3 d-flex justify-content-center">
          <span>{{ queryResult.data.length }} gevonden musea</span>
        </div>
      </div>
      <div v-if="!queryResult.data">
        <div class="mt-3 d-flex justify-content-center">Geen items gevonden</div>
      </div>

      <div class="card-deck">
        <a
          href
          v-on:click.prevent="showItem(item)"
          class="content-item card"
          v-for="item in queryResult.data"
          :key="item.id"
        >
          <template v-if="item.cover_image_thumb">
            <img
              data-sizes="auto"
              :data-src="item.cover_image_thumb.url"
              :data-srcset="item.cover_image_srcset"
              class="lazyload"
            />
          </template>
          <template v-if="!item.cover_image_thumb">geen foto</template>

          <div class="card-body">
            <h5 class="card-title">{{ item.title }}</h5>
            <p class="card-text" v-html="item.short_description"></p>

            <p class="card-text">
              <small class="text-muted render-breaks">{{ item.address }}</small>
            </p>
            <div v-if="item.classifier_terms.length">
              <p>
                <strong>Voorzieningen:</strong>
              </p>
              <p class="classifier-terms">
                <small
                  class="text-muted active-tag"
                  v-for="classifier in item.classifier_terms"
                  :key="classifier.id"
                >
                  <i
                    v-if="classifier.fa_icon"
                    :class="`fa ${classifier.fa_icon}`"
                    aria-hidden="true"
                  ></i>
                  {{ classifier.name }}
                </small>
              </p>
            </div>
          </div>
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import throttle from 'lodash.throttle';
import lazySizes from 'lazysizes'; // pikt juiste breedte voor img srcset
import 'lazysizes/plugins/attrchange/ls.attrchange'; // nodig na filtering

import drentheShape from '@/assets/drenthe_geojson.json';
import mapSettings from '@/constants/mapSettings';
import SearchInput from './SearchInput.vue';
import ClassifierSelect from './ClassifierSelect.vue';
import GoogleMapLoader from './GoogleMapLoader.vue';
import GoogleMapMarker from './GoogleMapMarker.vue';
import GoogleMapMarkerCluster from './GoogleMapMarkerCluster.vue';
import GoogleMapSpiderfier from './GoogleMapSpiderfier.vue';
import GoogleMapGeoJson from './GoogleMapGeoJson.vue';
import GoogleMapInfoWindow from './GoogleMapInfoWindow.vue';

lazySizes.cfg.loadMode = 0; // no eager loading of images

const museumMarker = require('@/assets/museum_marker.png');

export default {
  name: 'CollectionFilter',
  components: {
    SearchInput,
    ClassifierSelect,
    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMapGeoJson,
    GoogleMapInfoWindow,
    GoogleMapMarkerCluster,
    GoogleMapSpiderfier,
  },
  data() {
    return {
      baseUrl: '',
      searchFilterValue: null,
      classifierListData: null,
      classifierValues: {},
      queryResult: null,
      loading: false,
      filterRoot: null,
      cancelToken: null,
      selectedMarker: null,
    };
  },
  props: {
    searchPlaceholder: String,
    filterOnString: String,
    latitude: Number,
    longitude: Number,
    zoom: Number,
    skip: Array,
  },
  computed: {
    googleMapsKey() {
      return `${process.env.VUE_APP_GOOGLE_MAPS_KEY}`;
    },
    icon() {
      return { url: museumMarker };
    },
    geojson() {
      return drentheShape;
    },
    mapConfig() {
      return {
        ...mapSettings,
        fillColor: "green",
        center: this.mapCenter,
        zoom: this.zoom,
      };
    },

    mapCenter() {
      return { lat: this.latitude, lng: this.longitude };
    },
  },
  mounted() {
    this.filterRoot = this.$refs['filter-root'];

    let classifierListUrl = `${process.env.VUE_APP_API_URL}classifiers/`;

    // slugs to skip (mainly used to disable certain classifiers on drentsemusea.nl)
    let skipList = this.skip;

    if (skipList) {
      if (typeof skipList === 'string') {
        skipList = skipList.split(',');
      }
      const qs = [];
      skipList.forEach((item) => {
        qs.push(`skip=${item}`);
      });
      classifierListUrl += `?${qs.join('&')}`;
    }

    axios.get(classifierListUrl).then((response) => {
      this.classifierListData = response.data;
    });

    this.queryCollection();
  },
  created() {
    // just to make sure that we limit the number of calls to our backend a littles
    // this.queryCollection = _.throttle(this.queryCollection, 500);
    this.queryCollection = throttle(this.queryCollection, 500);
  },
  destroyed() {},
  methods: {
    markerSelected(item) {
      this.selectedMarker = item;
    },
    popupClosed() {
      this.selectedMarker = null;
    },
    showItem(item) {
      window.location.href = item.url;
    },

    getQueryParams() {
      const params = new URLSearchParams();

      Object.keys(this.classifierValues).forEach((key) => {
        const value = this.classifierValues[key];
        value.forEach((el) => {
          params.append('classifier', el.id);
        });
      });

      if (this.searchFilterValue) {
        params.append('search', this.searchFilterValue);
      }

      return params;
    },

    queryCollection($state) {
      if (this.cancelToken) {
        // cancel ongoing request
        this.cancelToken.cancel('Only one request allowed at a time.');
      }
      this.cancelToken = axios.CancelToken.source();

      return this.doQueryMusea(this.getQueryParams(), $state);
    },

    doQueryMusea(params) {
      this.loading = true;
      const museaUrl = `${process.env.VUE_APP_API_URL}museums/`;

      const qq = async () => {
        const promise = axios.get(museaUrl, {
          params,
          cancelToken: this.cancelToken.token,
        });
        await promise
          .then((response) => {
            this.queryResult = response;
            this.loading = false;
          })
          .catch((error) => {
            // error callback
            if (axios.isCancel(error)) {
              // no worries
            } else {
              // handle error
              this.loading = false;
              throw error.message;
            }
          })
          .then(() => {});
      };
      qq();
    },
    doFilter() {
      // this.queryResult = null; // causes flickering on IE
      this.queryCollection();
    },
  },
  watch: {
    classifierValues: {
      handler() {
        this.doFilter();
      },
      deep: true,
    },

    searchFilterValue() {
      this.doFilter();
    },
  },
};
</script>

<style lang="scss">
.render-breaks {
  white-space: pre;
}

.filter {
  display: block;
}

.map-block {
  height: 600px;
}

.classifier-terms {
  display: flex;
  flex-flow: wrap;
  margin-bottom: 0;

  .active-tag {
    padding: 5px;
    border-radius: 5px;
    background-color: #e4e4e4;
    color: rgba(0, 0, 0, 0.6) !important;
    margin-bottom: 5px;
    margin-right: 5px;
  }
}
</style>
