var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"filter-root",staticClass:"musea-filter container"},[_c('div',{staticClass:"top-row row p-3 bg-white border rounded"},[_c('div',{staticClass:"filter-block col-12 col-md-4"},[_c('div',{staticClass:"filter pl-0 pr-0 pr-sm-2"},[_c('search-input',{attrs:{"placeholder":_vm.searchPlaceholder},model:{value:(_vm.searchFilterValue),callback:function ($$v) {_vm.searchFilterValue=$$v},expression:"searchFilterValue"}})],1),_vm._l((_vm.classifierListData),function(classifier){return _c('div',{key:classifier.id,staticClass:"filter pl-0 pr-0 pr-sm-2"},[_c('classifier-select',{attrs:{"filterData":classifier,"placeholder":(_vm.filterOnString + " " + (classifier.name))},model:{value:(_vm.classifierValues[classifier.id]),callback:function ($$v) {_vm.$set(_vm.classifierValues, classifier.id, $$v)},expression:"classifierValues[classifier.id]"}})],1)}),(_vm.loading)?_c('div',{staticClass:"mt-3 mb-3 d-flex justify-content-center"},[_vm._m(0)]):_vm._e()],2),_c('div',{staticClass:"map-block col-12 col-md-8"},[_c('GoogleMapLoader',{attrs:{"mapConfig":_vm.mapConfig,"apiKey":_vm.googleMapsKey},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var google = ref.google;
var map = ref.map;
return [_c('GoogleMapGeoJson',{attrs:{"google":google,"map":map,"geojson":_vm.geojson}}),_c('GoogleMapInfoWindow',{attrs:{"google":google,"map":map,"selectedMarker":_vm.selectedMarker},on:{"closed":_vm.popupClosed}}),(_vm.queryResult && google)?[_c('GoogleMapMarkerCluster',{attrs:{"google":google,"map":map,"imagePath":"https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var markerCluster = ref.markerCluster;
return [_c('GoogleMapSpiderfier',{attrs:{"google":google,"map":map},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var spiderfier = ref.spiderfier;
return _vm._l((_vm.queryResult.data),function(item){return _c('GoogleMapMarker',{key:item.id,attrs:{"marker":item.to_geojson,"google":google,"map":map,"markerCluster":markerCluster,"spiderfier":spiderfier,"icon":_vm.icon},on:{"marker-selected":_vm.markerSelected}})})}}],null,true)})]}}],null,true)})]:_vm._e()]}}])})],1)]),(_vm.queryResult)?[(_vm.queryResult.data)?_c('div',[_c('div',{staticClass:"m-3 d-flex justify-content-center"},[_c('span',[_vm._v(_vm._s(_vm.queryResult.data.length)+" gevonden musea")])])]):_vm._e(),(!_vm.queryResult.data)?_c('div',[_c('div',{staticClass:"mt-3 d-flex justify-content-center"},[_vm._v("Geen items gevonden")])]):_vm._e(),_c('div',{staticClass:"card-deck"},_vm._l((_vm.queryResult.data),function(item){return _c('a',{key:item.id,staticClass:"content-item card",attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.showItem(item)}}},[(item.cover_image_thumb)?[_c('img',{staticClass:"lazyload",attrs:{"data-sizes":"auto","data-src":item.cover_image_thumb.url,"data-srcset":item.cover_image_srcset}})]:_vm._e(),(!item.cover_image_thumb)?[_vm._v("geen foto")]:_vm._e(),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"card-text",domProps:{"innerHTML":_vm._s(item.short_description)}}),_c('p',{staticClass:"card-text"},[_c('small',{staticClass:"text-muted render-breaks"},[_vm._v(_vm._s(item.address))])]),(item.classifier_terms.length)?_c('div',[_vm._m(1,true),_c('p',{staticClass:"classifier-terms"},_vm._l((item.classifier_terms),function(classifier){return _c('small',{key:classifier.id,staticClass:"text-muted active-tag"},[(classifier.fa_icon)?_c('i',{class:("fa " + (classifier.fa_icon)),attrs:{"aria-hidden":"true"}}):_vm._e(),_vm._v("\n                "+_vm._s(classifier.name)+"\n              ")])}),0)]):_vm._e()])],2)}),0)]:_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('strong',[_vm._v("Voorzieningen:")])])}]

export { render, staticRenderFns }