<script>
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
    icon: {
      type: Object,
      required: true,
    },
    markerCluster: {
      type: Object,
      required: false,
    },
    spiderfier: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      markerObj: null,
      infoWindow: null,
    };
  },

  computed: {
    position() {
      return {
        lat: this.marker.geometry.coordinates[1],
        lng: this.marker.geometry.coordinates[0],
      };
    },
  },

  mounted() {
    const { Marker } = this.google.maps;
    this.markerObj = new Marker({
      position: this.position,
      map: this.map,
      icon: this.icon,
    });

    if (this.markerCluster) {
      this.markerCluster.addMarker(this.markerObj);
    }

    if (this.spiderfier) {
      this.spiderfier.addMarker(this.markerObj, () => {
        this.$emit('marker-selected', this.marker);
      });
    } else {
      // spiderfier catches all clicks, but if we don't have spiderfier
      // let the marker handle the click
      this.markerObj.addListener('click', () => {
        this.$emit('marker-selected', this.marker);
      });
    }
  },
  beforeDestroy() {
    if (this.markerCluster) {
      this.markerCluster.removeMarker(this.markerObj);
    }
    this.markerObj.setMap(null);
  },
  render() {
    return null;
  },
};
</script>
